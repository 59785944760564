* {
  box-sizing: border-box;
}

html {
  background-color: #edcb96;
}

.App{
  margin-top: -20px;
  margin-bottom: -15px;
  background-color: #BF986F;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.transition-group {
  position: relative;
}
.route-section {
position: absolute;
width: 100%;
top: 0;
left: 0;
}

.about-me, .projects-container, .contact {
  background-color: #EDCB96;
  height: auto;
  margin-top: 1.06rem;
}

.p-break {
  padding: .6rem;
  display: block;
}

.project_desc, .tech_stack {
  font-weight: bold;
}

label {
  font-family: 'Sniglet';
}

input#email_add, input#full_name, textarea#message {
  border: black solid 1px;
  border-radius: .3rem;
  margin-bottom: 12px;
}

input[type="submit"] {
  border: black solid 1px;
  border-radius: .3rem;
  margin-top: 2rem;
}

#github_a, #email_a, #linkedin_a {
  display: flex;
  flex-direction: column;
  color: #373733;
  font-weight: bold;
}

.github:hover, .email:hover, .linkedin:hover {
  color: white;
}

@media screen and (max-width: 374px) {
  /* Header & About */
  p {
    font-family: Montserrat;
  }

  .header-container {
    background-color: #212922;
    width: 100vw;
    height: 18vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1, h2 {
    font-family: MuseoModerno;
    text-align: center
  }

  h3 {
    font-family: Sniglet;
    color: #EDCB96
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-list {
    text-decoration: none;
  }

  .list-item {
    list-style-type: none;
    margin-right: 20px;
    font-family: MuseoModerno;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #AEF6C7
  }

  a:visited {
    color: #AEF6C7
  }

  a:hover {
    color: white

  }

  a:active {
    color: #5B8266;
  }

  .header-header1 {
    color: #AEF6C7;
    font-size: 20px;
    letter-spacing: -1px;
  }

  .about-header {
    padding-top: 30px
  }
  
  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
  }  
  
  .about-paragraph {
    padding: 20px 2rem;
    text-align: start;
    font-weight: bold;
    font-size: 14px;
    background-color: #edcb96;
    color: #3f3a32;
  }

  /* Projects */
  .project_title, .project_desc {
    color: #212922;
  }
  
  .project {
    background-color: #edcb96;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
  }
  
  .project_title {
    padding-right: 0;
  }

  .project_header {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .p1_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .p2_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .project_desc, .tech_stack {
    padding: 20px;
  }

  .tech_stack {
    padding: 20px;
    margin-top: -27px;
    font-size: 13px;
    color: #580606;
  }

  .projectLinks_container {
    display: flex;
    flex-direction: row;
    background-color: #212922;
    border-radius: 15px;
  }

  .projectLinks {
    margin: 0 25px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_header {
    padding-top: 30px;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;
    color: #1d1912;
  }

  .email, .linkedin, .github {
    text-align: center
  }
}

@media screen and (min-width: 375px) {
  p {
    font-family: Montserrat;
  }

  

  .header-container {
    background-color: #212922;
    width: 100vw;
    height: 18vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1, h2 {
    font-family: MuseoModerno;
    text-align: center
  }

  h3 {
    font-family: Sniglet;
    color: #EDCB96
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-list {
    text-decoration: none;
  }

  .list-item {
    list-style-type: none;
    margin-right: 20px;
    font-family: MuseoModerno;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #AEF6C7
  }

  a:visited {
    color: #AEF6C7
  }

  a:hover {
    color: white

  }

  a:active {
    color: #5B8266;
  }

  .header-header1 {
    color: #AEF6C7;
    font-size: 20px;
    letter-spacing: -1px;
  }

  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .about-paragraph {
    padding: 20px 2rem;
    text-align: start;
    font-weight: bold;
    font-size: 14px;
    background-color: #edcb96;
    color: #3f3a32;
  }  

  .about-header {
    padding-top: 30px
  }
  
  /* Projects */
  .project_title, .project_desc {
    color: #212922;
  }
  
  .project {
    background-color: #edcb96;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
  }

  .project_title {
    padding-right: 0;
  }

  .project_header {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .p1_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .p2_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .project_desc, .tech_stack {
    padding: 20px;
    width: 16rem;
    text-align: center;
  }

  .tech_stack {
    padding: 20px;
    margin-top: -27px;
    font-size: 13px;
    color: #580606;
  }

  .projectLinks_container {
    display: flex;
    flex-direction: row;
    background-color: #212922;
    border-radius: 15px;
  }

  .projectLinks {
    margin: 0 25px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_header {
    padding-top: 30px;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 70px;
    color: #1d1912;
  }

  .email, .linkedin, .github {
    text-align: center
  }
}

@media screen and (min-width: 550px) {
  p {
    font-family: Montserrat;
  }

  .header-container {
    background-color: #212922;
    width: 100vw;
    height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1, h2 {
    font-family: MuseoModerno;
    text-align: center
  }

  h3 {
    font-family: Sniglet;
    color: #EDCB96
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-list {
    text-decoration: none;
  }

  .list-item {
    list-style-type: none;
    margin-right: 20px;
    font-family: MuseoModerno;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #AEF6C7
  }

  a:visited {
    color: #AEF6C7
  }

  a:hover {
    color: white

  }

  a:active {
    color: #5B8266;
  }

  .header-header1 {
    color: #AEF6C7;
    font-size: 30px;
    letter-spacing: -1px;
  }

  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .about-paragraph {
    padding: 20px 2rem;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #edcb96;
    color: #3f3a32;
  }  

  .about-header {
    padding-top: 30px
  }

  /* Projects */
  .project_title, .project_desc {
    color: #212922;
  }

  .project_title {
    padding-right: 0;
  }
  
  .project {
    background-color: #edcb96;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
  }

  .project_header {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .p1_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .p2_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .project_desc, .tech_stack {
    padding: 20px;
    width: 16rem;
    text-align: center;
  }

  .tech_stack {
    padding: 20px;
    margin-top: -27px;
    font-size: 13px;
    color: #580606;
  }

  .projectLinks_container {
    display: flex;
    flex-direction: row;
    background-color: #212922;
    border-radius: 15px;
  }

  .projectLinks {
    margin: 0 25px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_header {
    padding-top: 30px;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 70px;
    color: #1d1912;
  }

  .email, .linkedin, .github {
    text-align: center
  }
}

@media screen and (min-width: 754px) {
  p {
    font-family: Montserrat;
  }

  .header-container {
    background-color: #212922;
    width: 100vw;
    height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1, h2 {
    font-family: MuseoModerno;
    text-align: center
  }

  h3 {
    font-family: Sniglet;
    color: #EDCB96
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-list {
    text-decoration: none;
  }

  .list-item {
    list-style-type: none;
    margin-right: 20px;
    font-family: MuseoModerno;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #AEF6C7
  }

  a:visited {
    color: #AEF6C7
  }

  a:hover {
    color: white

  }

  a:active {
    color: #5B8266;
  }

  .header-header1 {
    color: #AEF6C7;
    font-size: 30px;
    letter-spacing: -1px;
  }

  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .about-paragraph {
    padding: 20px 5rem;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #edcb96;
    color: #3f3a32;
  }  

  .about-header {
    padding-top: 30px
  }

  /* Projects */
  .project_title, .project_desc {
    color: #212922;
  }

  .project_title {
    padding-right: 0;
  }
  
  .project {
    background-color: #edcb96;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
  }

  .project_header {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .p1_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .p2_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .project_desc, .tech_stack {
    padding: 20px;
    width: 16rem;
    text-align: center;
  }

  .tech_stack {
    padding: 20px;
    margin-top: -27px;
    font-size: 13px;
    color: #580606;
  }

  .projectLinks_container {
    display: flex;
    flex-direction: row;
    background-color: #212922;
    border-radius: 15px;
  }

  .projectLinks {
    margin: 0 25px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_header {
    padding-top: 30px;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 70px;
    color: #1d1912;
  }

  .email, .linkedin, .github {
    text-align: center
  }
}

@media screen and (min-width: 1024px) {
  p {
    font-family: Montserrat;
  }

  .header-container {
    background-color: #212922;
    width: 100vw;
    height: 13vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  h1, h2 {
    font-family: MuseoModerno;
    text-align: center
  }

  h3 {
    font-family: Sniglet;
    color: #EDCB96
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .nav-list {
    text-decoration: none;
  }

  .list-item {
    list-style-type: none;
    margin-right: 20px;
    font-family: MuseoModerno;
    font-size: 24px;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: #AEF6C7
  }

  a:visited {
    color: #AEF6C7
  }

  a:hover {
    color: white

  }

  a:active {
    color: #5B8266;
  }

  .info {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .header-header1 {
    color: #AEF6C7;
    font-size: 30px;
    letter-spacing: -1px;
  }

  .about-paragraph {
    padding: 20px 20rem;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #edcb96;
    color: #3f3a32;
    
  }  

  .about-header {
    padding-top: 30px
  }

  /* Projects */
  .project_title, .project_desc {
    color: #212922;
  }

  .project_title {
    padding-right: 33px;
  }

  pre {
    display: block;
    font-size: 1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-family: Sniglet;
  }
  
  .project {
    background-color: #edcb96;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
  }

  .project_header {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .p1_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .p2_img {
    height: auto;
    width: 16rem;
    text-align: center;
    border-radius: 10px;
  }

  .project_desc, .tech_stack {
    text-align: left;
    width: 20rem;
  }

  .tech_stack {
    padding: 20px;
    margin-top: -27px;
    font-size: 13px;
    font-weight: bold;
    color: #580606;
  }

  .projectLinks_container {
    display: flex;
    flex-direction: row;
    background-color: #212922;
    border-radius: 15px;
  }

  .projectLinks {
    margin: 0 25px;
  }

  #buddyup-title {
    padding-right: 5.3125rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_header {
    padding-top: 30px;
  }

  .social_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 70px;
    color: #1d1912;
  }

  .email, .linkedin, .github {
    text-align: center
  }
}